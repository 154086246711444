<template>
    <dashboard-layout>
        <v-row>
            <v-col cols="12" md="7" lg="5">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    Profile Views
                                </v-card-title>
                                <chart-view
                                    v-if="profileViewsLoaded"
                                    :data="chartsData.profileViews"
                                />
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    Track Listens
                                </v-card-title>
                                <chart-view
                                    v-if="trackListensLoaded"
                                    :data="chartsData.trackListens"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="5" lg="7">
                <v-container fluid>
                    <v-row>
                        <v-col
                            v-for="card in Object.values(cards)" :key="card.title"
                            cols="12" lg="6" xl="4">
                            <v-card
                                class="pa-2"
                                outlined
                                tile
                            >
                                <v-card-title>
                                    <v-icon small color="primary" class="mr-2">{{ card.icon }}</v-icon>
                                    {{ card.title }}
                                </v-card-title>
                                <v-card-text
                                    v-if="card.text"
                                    v-html="card.text"
                                    >
                                </v-card-text>
                                <v-card-actions v-if="card.to">
                                    <v-btn
                                        :to="card.to"
                                        small
                                        text
                                        color="accent"
                                    >
                                        {{ card.toLabel }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import ChartView from "@/components/ChartView";
import cards from "./cards";
import {chartsData} from '@/plugins/charts-data'
import ArtistProfileView from "@/models/ArtistProfileView";
import ArtistTrackPlay from "@/models/ArtistTrackPlay";
import DashboardLayout from "@/layouts/DashboardLayout";
import Statistics from "../dashboard/Statistics";

export default {
    name: "dashboard-index",
    components: {DashboardLayout, ChartView},
    data: function () {
        return {
            profileViewsCount: null,
            artistLoading: false,
            profileViewsLoaded: false,
            trackListensLoaded: false,
            cards,
            chartsData: {
                profileViews: null,
                trackListens: null,
            },
            favArtists: [],
            favVenues: [],
        }
    },
    methods: {
        async init() {
            this.artistLoading = true
            const statistics = new Statistics({user: this.$auth.user(), role: 'artist'})
            await statistics.init()
            for (let key in this.cards) {
                Object.assign(this.cards[key], statistics.cards[key])
            }
            this.artistLoading = false
        },
        async loadProfileViews() {
            this.profileViewsLoaded = false
            this.chartsData.profileViews = chartsData(await ArtistProfileView.getNewCounts(), '#ce790a')
            this.profileViewsLoaded = true
        },
        async loadTrackListens() {
            this.trackListensLoaded = false
            this.chartsData.trackListens = chartsData(await ArtistTrackPlay.getNewCounts(), '#ce790a')
            this.trackListensLoaded = true
        },
    },
    async mounted() {
        this.loadProfileViews()
        this.loadTrackListens()
        this.init()
    }
}
</script>

<style scoped>

</style>

export default {
    profileViews: {
        icon: 'mdi-eye',
        title: 'Profile Views',
        text: 'Profile Views:'
    },
    favorites: {
        to: {name: 'dashboard.favourites'},
        toLabel: 'View all',
        icon: 'mdi-heart',
        title: 'Favorites',
        text: 'Recently added: • first • second'
    },
    tracks: {
        to: {name: 'artist.tracks.index'},
        toLabel: 'View all',
        icon: 'mdi-playlist-music',
        title: 'Tracks',
        text: 'Total Tracks:'
    },
    videos: {
        to: {name: 'artist.videos.index'},
        toLabel: 'View all',
        icon: 'mdi-video',
        title: 'Videos',
        text: 'Total Videos:'
    },
    gigs: {
        to: {name: 'artist.gigs.index'},
        toLabel: 'View all',
        icon: 'mdi-calendar',
        title: 'Gigs',
        text: 'Total Gigs:'
    },
}
